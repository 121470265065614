import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './../authentication/auth.service';
import { SocialAuthService, SocialUser } from 'angularx-social-login';
import {
  FacebookLoginProvider,
  GoogleLoginProvider
} from 'angularx-social-login';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  email: [''];
  password: [''];
  user: SocialUser;
  loggedIn: boolean;
  graphMeEndpoint = 'https://graph.microsoft.com/v1.0/me';
  profile: any;
  dataToSignIn: any;
  socialType: any;
  signUp: any;
  socialSignUp = false;
  socialLogin = null;
  microsoftLogin = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private broadcastService: BroadcastService,
    private messageService: MessageService,
    private spinnerService: Ng4LoadingSpinnerService,
    private AuthService: SocialAuthService,
    private msalService: MsalService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    if (this.microsoftLogin == true) {
      this.getProfile();
    }
  }

  signIn(formm) {
    this.spinnerService.show();

    if (formm['password'] != null) {
      this.socialLogin = false;
    }

    if (this.socialLogin == false) {
      this.dataToSignIn = {
        socialType: 'normal',
        email: this.email,
        password: this.password
      };
    } else if (this.socialLogin == true) {
      if (this.socialType == 'google' || this.socialType == 'facebook') {
        this.dataToSignIn = {
          socialType: this.socialType,
          socialId: this.user['id'],
          firstName: this.user['firstName'],
          lastName: this.user['lastName'],
          email: this.user['email'],
          imageUrl: this.user['photoUrl']
        };
      } else {
        this.dataToSignIn = {
          socialType: this.socialType,
          socialId: formm['accountIdentifier'],
          firstName: formm['idToken']['name'],
          // lastName : formm['lastName'],
          email: formm['email'],
          imageUrl: formm['userName']
        };
      }
    }
    console.log('data to signIn', this.dataToSignIn);
    if (this.dataToSignIn != null) {
      this.authService.signIn(this.dataToSignIn).then(res => {
        console.log('login res', res);
        if (res['status'] == 1) {
          this.spinnerService.hide();

          this.messageService.add({
            severity: 'success',
            summary: 'Success Message',
            detail: 'Logged in successfully!'
          });
          localStorage.setItem('user_image', res['data']['imageUrl']);
          localStorage.setItem('login_type', res['data']['socialType']);
          // this.socialLogin == null;
          this.authService.storeUserData(
            res['data']['accessToken'],
            res['phpAccessToken']
          );
          setTimeout(() => {
            this.router.navigate(['/dashboard']);
          }, 1000);
          this.formmReset();
          return;
        } else {
          this.spinnerService.hide();

          this.messageService.add({
            severity: 'error',
            summary: 'Error Message',
            detail: res['message']
          });
          localStorage.clear();
        }
        return;
      });
    }
  }

  signInWithGoogle(): void {
    console.log('sign in with google');
    this.socialLogin = true;
    this.socialType = 'google';
    this.AuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => {
      console.log('x', x);
      if (x) {
        this.user = x;
        this.signIn(this.user);
      }
    });
  }

  signInWithFB(): void {
    console.log('sign in with facebook');
    this.socialLogin = true;
    this.socialType = 'facebook';
    this.AuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => {
      console.log('x', x);
      if (x) {
        this.user = x;
        this.signIn(this.user);
      }
    });
  }

  signInWithMicrosoft() {
    console.log('microsoft login_______________');
    const isIE =
      window.navigator.userAgent.indexOf('MSIE ') > -1 ||
      window.navigator.userAgent.indexOf('Trident/') > -1;
    this.microsoftLogin == true;
    this.socialLogin = true;
    this.socialType = 'microsoft';
    if (isIE) {
      this.msalService.loginRedirect({
        extraScopesToConsent: ['user.read', 'openid', 'profile']
      });
      let microsoftUser = this.msalService.getAccount();
      console.log('microsft user', microsoftUser);
      this.signIn(microsoftUser);
      // this.getProfile();
    } else {
      this.msalService.loginPopup({
        extraScopesToConsent: ['user.read', 'openid', 'profile']
      });
      let microsoftUser = this.msalService.getAccount();
      console.log('microsft user', microsoftUser);
      if (microsoftUser) {
        this.signIn(microsoftUser);
      }
      // this.getProfile();
    }
  }

  getProfile() {
    this.http
      .get(this.graphMeEndpoint)
      .toPromise()
      .then(profile => {
        this.profile = profile;
        console.log('user', profile);
      });
  }

  back() {
    localStorage.setItem('new_user', 'false');
    setTimeout(() => {
      this.router.navigate(['/landing']);
    }, 1000);
  }

  formmReset() {
    this.dataToSignIn = null;
    this.socialLogin = null;
  }
}
