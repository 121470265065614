<div class="signup-container">
<p-toast [style]="{marginTop: '20px'}"></p-toast>
    <div class="logo text-center">
        <img class="logo-image" src="../../assets/logo.png">
    </div>

    <div class="back">
        <button type="button" class="btn btn-light back-button" (click)="back()"><i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp; Back</button>
    </div>

    <div class="card card-body">
        <h4 class="text-center text-light">Sign up</h4>
        <div class="row" style="margin-top: 15px;">
            <div class="col-md-4">
                <button type="button" class="btn google-btn" (click)="signInWithGoogle()"><img class="account-btn" src="../../assets/google-plus-button.png"></button>
            </div>
            <div class="col-md-4">
                <button type="button" class="btn fb-btn"  (click)="signInWithFB()"><img class="account-btn" src="../../assets/facebook-button.png"></button>
            </div>
            <div class="col-md-4">
                <button type="button" class="btn microsoft-button" (click)="signInWithMicrosoft()"><img class="account-btn" src="../../assets/microsoft-button.png"></button>
            </div>
        </div>
        <p class="text-light mt-10 dash-text" style="margin:10px 0 20px 0">
            ----------------------------------------&nbsp;  OR  &nbsp;---------------------------------------
        </p>
        <form #f="ngForm" role="form" (submit)="signUp(f)">
            <div class="row">
                <div class="col-md-6">
                    <div class="input-container input-group">  
                        <span class="input-group-addon addon-image"><img class="icon" src="../../assets/user.png"></span>
                        <input id="firstName" type="text" class="form-control input-field" name="firstName" placeholder="First Name" (keydown.space)="$event.preventDefault()"
                        [(ngModel)]="firstName" (keypress)="alphabetOnly($event)">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-container input-group">
                        <span class="input-group-addon addon-image"><img class="icon" src="../../assets/user.png"></span>
                        <input id="lastName" type="text" class="form-control input-field" name="lastName" placeholder="Last Name" (keydown.space)="$event.preventDefault()"
                         [(ngModel)]="lastName" (keypress)="alphabetOnly($event)">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-container input-group">
                        <span class="input-group-addon addon-image"><img class="icon" src="../../assets/email.png"></span>
                        <!-- <input id="email" type="text" class="form-control input-field" name="email" placeholder="Email" [(ngModel)]="email"> -->
                        <input type="email" class="form-control input-field" placeholder="Email" (keydown.space)="$event.preventDefault()"
                        pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$" id="email" name="email" ngModel #emailref="ngModel"
                        [(ngModel)]="email" required>
                        <br>
                        <div *ngIf="emailref.errors &&(emailref.touched || emailref.dirty) " class="aler alert-danger">
                            <div [hidden]="!emailref.errors?.pattern">
                            Invalid Email Format
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-container input-group">
                        <span class="input-group-addon addon-image"><img class="icon" src="../../assets/mobile.png"></span>
                        <!-- <input id="contactNumber" type="text" class="form-control input-field" name="contactNumber" placeholder="Phone" [(ngModel)]="contactNumber"> -->
                        <input type="text" class="form-control input-field" placeholder="contactNumber*" (keydown.space)="$event.preventDefault()"
                        pattern="^.*(?=.{10,}).*$" id="contactNumber" name="contactNumber" ngModel #contactNumberref="ngModel"
                        [(ngModel)]="contactNumber" required (keypress)="numberOnly($event)">
            
                        <div *ngIf="contactNumberref.errors &&(contactNumberref.touched || contactNumberref.dirty) " class="aler alert-danger">
                            <div [hidden]="!contactNumberref.errors?.pattern">
                            Atleast 10 digits is must
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-container input-group">
                        <span class="input-group-addon addon-image"><img class="icon" src="../../assets/key.png"></span>
                        <!-- <input id="password" type="text" class="form-control input-field" name="password" placeholder="Password" [(ngModel)]="password"> -->
                        <input type="password" class="form-control input-field" placeholder="Password*" (keydown.space)="$event.preventDefault()"
                        pattern="^.*(?=.{8,}).*$" id="password" name="password" ngModel #passwordref="ngModel"
                        [(ngModel)]="password" required>
            
                        <div *ngIf="passwordref.errors &&(passwordref.touched || passwordref.dirty) " class="aler alert-danger">
                            <div [hidden]="!passwordref.errors?.pattern">
                            Length is too short
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-container input-group">
                        <span class="input-group-addon addon-image"><img class="icon" src="../../assets/key.png"></span>
                        <input id="confirmPassword" type="password" class="form-control input-field" name="confirmPassword" (keydown.space)="$event.preventDefault()"
                         placeholder="Confirm Password" [(ngModel)]="confirmPassword">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-container captcha-box">
                        <input type="checkbox" style="margin:15px">
                        <label class="form-check-label label-color" for="gridCheck1" style="padding-top:10px; font-size: 14px">
                            i'm not robot
                        </label>
                        <!-- <p-captcha siteKey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" (onResponse)="captchaResponse($event)"></p-captcha> -->
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/RecaptchaLogo.svg/1024px-RecaptchaLogo.svg.png"
                            style="width:40px; margin-left: 70px;" />
                        <!-- <re-captcha  #captchaRef="reCaptcha" (resolved)="resolved($event)" name="recaptchaReactive" [(ngModel)]="recaptchaReactive" siteKey="6LdpvLUZAAAAAPl7WDECF0S6HnVV7I9dTe_tnK3n"></re-captcha> -->
                    </div>
                </div>
                <div class="col-md-6">
                    <button type="submit" class="btn btn-primary signup-button" 
                    [disabled]="!firstName || !lastName || !emailref.valid || !contactNumberref.valid || !passwordref.valid || !confirmPassword || !termsAndConditions">SIGN UP</button>
                </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-container">
                            <input type="checkbox" style="margin-top: 4px;margin-left: 5px;" name="termsAndConditions" [(ngModel)]="termsAndConditions">
                            <label class="form-check-label label-color" for="gridCheck1" style="padding-left: 5px; font-size: 14px;">
                                I agree to <a href="#" style="color: white">terms and conditions</a>
                            </label>
                        </div>
                    </div>
                </div>
         </form>
         <p class="text-light dash-text" style="margin-bottom: 0px; margin-top: -16px;">
                ---------------------------------------------------------------------------------------
        </p>
        <div class="text-center text-light">
            Already have an account? <a  [routerLink]="['/signin']" style="color: white"><b>Sign In</b></a>
        </div> 
        
    </div>
    <br>
</div>