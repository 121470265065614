<div class="forgot-container">
    <p-toast [style]="{marginTop: '20px'}"></p-toast>
    <div class="logo text-center">
        <img class="logo-image" src="../../assets/logo.png">
    </div>

    <div class="back">
        <button type="button" class="btn btn-light back-button" (click)="back()"><i class="fa fa-arrow-left"
                aria-hidden="true"></i>&nbsp; Back</button>
    </div>

    <div class="card card-body">
        <h4 class="text-center text-light" style="margin-top: 15px;">Reset Your Password</h4>
        <br>
        <form #f="ngForm" role="form" (submit)="resetPassword(f)" style="margin-left: 7%;">
            <div class="input-container input-group">
                <span class="input-group-addon addon-image"><img class="icon" src="../../assets/user.png"></span>
                <input type="email" class="form-control input-field" placeholder="Email"
                    (keydown.space)="$event.preventDefault()" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$"
                    id="email" name="email" ngModel #emailref="ngModel" [(ngModel)]="email" required>
                <br>
                <div *ngIf="emailref.errors &&(emailref.touched || emailref.dirty) " class="aler alert-danger">
                    <div [hidden]="!emailref.errors?.pattern">
                        Invalid Email Format
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5">
                </div>
                <div class="col-md-7">
                    <button type="submit" class="btn btn-primary reset-button"
                        [disabled]="!emailref.valid">Reset</button>
                </div>
            </div>
            <br>
        </form>
    </div>
</div>