<div class="signup-container">
    <p-toast [style]="{marginTop: '20px'}"></p-toast>
    <div class="logo text-center">
        <img class="logo-image" src="../../assets/logo.png">
    </div>

    <div class="back">
        <button type="button" class="btn btn-light back-button" (click)="back()"><i class="fa fa-arrow-left"
                aria-hidden="true"></i>&nbsp; Back</button>
    </div>

    <div class="card card-body">
        <h4 class="text-center text-light" style="margin-top: 15px;">Login to your account</h4>
        <br>
        <form #f="ngForm" role="form" (submit)="signIn(f.value)" style="margin-left: 7%;">
            <div class="input-container input-group">
                <span class="input-group-addon addon-image"><img class="icon"src="../../assets/user.png"></span>
                <input type="email" class="form-control input-field" placeholder="Email" (keydown.space)="$event.preventDefault()"
                pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$" id="email" name="email" ngModel #emailref="ngModel"
                [(ngModel)]="email" required>
                <br>
                <div *ngIf="emailref.errors &&(emailref.touched || emailref.dirty) " class="aler alert-danger">
                    <div [hidden]="!emailref.errors?.pattern">
                    Invalid Email Format
                    </div>
                </div>    
            </div>
            <div class="input-container input-group">
                <span class="input-group-addon addon-image"><img class="icon" src="../../assets/key.png"></span>
                <input id="password" type="password" class="form-control input-field" name="password" placeholder="Password"
                    [(ngModel)]="password">
            </div>
            <div class="checkbox text-left">
                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" style="margin-left: 15px;position: relative;top: 2px;">
                <label for="vehicle1" class="remember-checkbox">Remember Me</label><br>
            </div>
            <div class="row">
                <div class="col-md-5">
                    <a class="forgot-password" [routerLink]="['/forgot-password']">Forgot Password?</a>
                </div>
                <div class="col-md-7">
                    <button type="submit" class="btn btn-primary signup-button" [disabled]="!emailref.valid || !password">LOG IN</button>
                </div>
            </div>
            <br>
        </form>
        <div>
            <p class="text-light">
                ---------------------------&nbsp; OR &nbsp;-------------------------------
            </p>
        </div>
        <div class="row account-btn">
            <div class="col-md-4">
                <button type="button" class="btn" (click)="signInWithGoogle()"><img src="../../assets/google-plus-button.png"></button>
            </div>
            <div class="col-md-4">
                <button type="button" class="btn" (click)="signInWithFB()"><img src="../../assets/facebook-button.png"></button>
            </div>
            <div class="col-md-4">
                <button type="button" class="btn" (click)="signInWithMicrosoft()"><img src="../../assets/microsoft-button.png"></button>
            </div>
        </div>
        <br>
        <div class="text-center text-light">
            Don't have an account? <a  [routerLink]="['/signup']" style="color: white"><b>Sign up</b></a>
        </div>
    </div>

</div>