import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import {
  SocialLoginModule,
  SocialAuthServiceConfig
} from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';
import { AuthService } from './authentication/auth.service';
import { SignUpComponent } from './sign-up/sign-up.component';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { SignInComponent } from './sign-in/sign-in.component';
import { environment } from './../environments/environment';
import { AuthGuard } from './authentication/auth.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { CaptchaModule } from 'primeng/captcha';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;
@NgModule({
  declarations: [
    AppComponent,
    SignUpComponent,
    SignInComponent,
    ForgotPasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ToastModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RecaptchaModule,
    CaptchaModule,
    RecaptchaFormsModule,
    SocialLoginModule,
    Ng4LoadingSpinnerModule.forRoot(),
    MsalModule.forRoot(
      {
        auth: {
          clientId: '90330dda-2f6f-4f6d-9b66-92b6d54fe47e',
          // authority: '64a07108-e973-4409-b2f7-6a1400bbaf70',
          // redirectUri: 'https://www.meetation.com',
          validateAuthority: true,
          redirectUri: 'http://localhost:4200/',
          navigateToLoginRequestUrl: true
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE // Set to true for Internet Explorer 11
        }
      },
      {
        popUp: !isIE,
        consentScopes: ['user.read', 'openid', 'profile'],
        unprotectedResources: [],
        protectedResourceMap: [
          ['https://graph.microsoft.com/v1.0/me', ['user.read']]
        ],
        extraQueryParameters: {}
      }
    )
  ],
  providers: [
    AuthService,
    MessageService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleClientId)
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.faceboookAppId)
          }
        ]
      } as SocialAuthServiceConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
