import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class AuthService {
  authToken: any;
  token: any;
  baseUrl = environment.test;
  constructor(private http: HttpClient) {}

  signIn(data) {
    return this.http
      .post(this.baseUrl + 'user/login', data)
      .toPromise()
      .then(res => res);
  }

  signUp(data) {
    return this.http
      .post(this.baseUrl + 'user/signup', data)
      .toPromise()
      .then(res => res);
  }

  forgotPassword(data) {
    return this.http
      .post(this.baseUrl + 'user/forgot-password', data)
      .toPromise()
      .then(res => res);
  }

  addEvent(data) {
    let headers = {
      headers: { Authorization: localStorage.getItem('node_token') }
    };
    return this.http
      .post(this.baseUrl + 'event/add-event', data, headers)
      .toPromise()
      .then(res => res);
  }

  editEvent(data) {
    let headers = {
      headers: { Authorization: localStorage.getItem('node_token') }
    };
    return this.http
      .post(this.baseUrl + 'event/edit-event', data, headers)
      .toPromise()
      .then(res => res);
  }

  browseVenuesAndServices(data) {
    let headers = new HttpHeaders();
    headers = headers.append('key', 'a152e84173914146e4bc4f391sd0f686ebc4f31');
    headers = headers.append('accessToken', localStorage.getItem('php_token'));

    let formdata = new FormData();
    formdata.append('categories_ids', '2');
    formdata.append('categories_ids', data.categories_ids);
    formdata.append('sub_category_id', data.sub_category_id);
    formdata.append('rating', data.rating);

    if (data.zipcode != null) {
      formdata.append('zipcode', data.zipcode);
    }
    if (data.capacity != null) {
      formdata.append('capacity', data.capacity);
    }
    console.log("in service________", formdata)

    // return this.http.post("https://rapidpartydev.mobilytedev.com/meetationapi/servicesResults", data, headers).toPromise()
    // .then(res => res);
    return this.http
      .post(
        'https://rapidpartydev.mobilytedev.com/meetationapi/servicesResults',
        formdata,
        { headers: headers }
      )
      .toPromise()
      .then(res => res);
  }

  landingPageServices(data) {
    let headers = new HttpHeaders();
    headers = headers.append('key', 'a152e84173914146e4bc4f391sd0f686ebc4f31');
    headers = headers.append(
      'accessToken',
      'RAPIDVOLLEY_1564609259Ji4jLFkuIzBXCmAL'
    );

    let formdata = new FormData();
    formdata.append('categories_ids', '2');
    formdata.append('categories_ids', data.categories_ids);
    formdata.append('sub_category_id', data.sub_category_id);
    formdata.append('rating', data.rating);

    if (data.zipcode != null) {
      formdata.append('zipcode', data.zipcode);
    }
    if (data.capacity != null) {
      formdata.append('capacity', data.capacity);
    }

    return this.http
      .post(
        'https://rapidpartydev.mobilytedev.com/meetationapi/servicesResults',
        formdata,
        { headers: headers }
      )
      .toPromise()
      .then(res => res);
  }

  getServiceCategories() {
    // let dataToSend ={
    //   key: 'a152e84173914146e4bc4f391sd0f686ebc4f31',
    //   accessToken: localStorage.getItem('php_token')
    // }
    // let headers = { headers:  dataToSend }
    let headers = new HttpHeaders();
    headers = headers.append('key', 'a152e84173914146e4bc4f391sd0f686ebc4f31');
    headers = headers.append('accessToken', localStorage.getItem('php_token'));
    let formdata = new FormData();
    // return this.http.post("https://rapidpartydev.mobilytedev.com/meetationapi/services-categories-listing", headers).toPromise()
    // .then(res => res);

    return this.http
      .post(
        'https://rapidpartydev.mobilytedev.com/meetationapi/services-categories-listing',
        formdata,
        { headers: headers }
      )
      .toPromise()
      .then(res => res);
  }

  addToFavourite(data) {
    let headers = new HttpHeaders();
    headers = headers.append('key', 'a152e84173914146e4bc4f391sd0f686ebc4f31');
    headers = headers.append('accessToken', localStorage.getItem('php_token'));

    let formdata = new FormData();
    formdata.append('service_id', data);

    return this.http
      .post(
        'https://rapidpartydev.mobilytedev.com/meetationapi/add-service-to-favorite',
        formdata,
        { headers: headers }
      )
      .toPromise()
      .then(res => res);
  }

  removeFromFavourite(data) {
    let headers = new HttpHeaders();
    headers = headers.append('key', 'a152e84173914146e4bc4f391sd0f686ebc4f31');
    headers = headers.append('accessToken', localStorage.getItem('php_token'));

    let formdata = new FormData();
    formdata.append('service_id', data);

    return this.http
      .post(
        'https://rapidpartydev.mobilytedev.com/meetationapi/remove-service-from-favorite',
        formdata,
        { headers: headers }
      )
      .toPromise()
      .then(res => res);
  }

  getServiceDetail(data) {
    let headers = new HttpHeaders();
    headers = headers.append('key', 'a152e84173914146e4bc4f391sd0f686ebc4f31');
    headers = headers.append('accessToken', localStorage.getItem('php_token'));

    let formdata = new FormData();
    formdata.append('service_id', data);

    return this.http
      .post(
        'https://rapidpartydev.mobilytedev.com/meetationapi/serviceView',
        formdata,
        { headers: headers }
      )
      .toPromise()
      .then(res => res);
  }

  bookVenue(data, serviceData) {
    let headers = new HttpHeaders();
    headers = headers.append('key', 'a152e84173914146e4bc4f391sd0f686ebc4f31');
    headers = headers.append('accessToken', localStorage.getItem('php_token'));
    let eventId = localStorage.getItem('eventId');

    let formdata = new FormData();
    formdata.append('service_id', serviceData.service.id);
    formdata.append('event_id', eventId);
    formdata.append('booking_start_date', data.startDate);
    // formdata.append('booking_start_time', eventStartTime);
    formdata.append('booking_end_date', data.startTime);
    formdata.append('locationcharge', serviceData.service.location_charge);
    formdata.append('fixedcost', serviceData.service.fixed_cost_portion);
    formdata.append('booking_status', 'pending');
    formdata.append('courts', data.courts);
    formdata.append('hours_periods', data.hours_periods);

    return this.http
      .post(
        'https://rapidpartydev.mobilytedev.com/meetationapi/service-book',
        formdata,
        { headers: headers }
      )
      .toPromise()
      .then(res => res);
  }

  getEventsDetails(id) {
    let headers = new HttpHeaders();
    headers = headers.append('key', 'a152e84173914146e4bc4f391sd0f686ebc4f31');
    headers = headers.append('accessToken', localStorage.getItem('php_token'));

    return this.http
      .get(
        'https://rapidpartydev.mobilytedev.com/meetationapi/event-details?event_id=' +
          id,
        {
          headers: headers
        }
      )
      .toPromise()
      .then(res => res);
  }

  cartData() {
    let headers = new HttpHeaders();
    headers = headers.append('key', 'a152e84173914146e4bc4f391sd0f686ebc4f31');
    headers = headers.append('accessToken', localStorage.getItem('php_token'));
    // let formdata = new FormData();

    return this.http
      .get('https://rapidpartydev.mobilytedev.com/meetationapi/cart', {
        headers: headers
      })
      .toPromise()
      .then(res => res);
  }

  getEvents() {
    let headers = new HttpHeaders();
    headers = headers.append('key', 'a152e84173914146e4bc4f391sd0f686ebc4f31');
    headers = headers.append('accessToken', localStorage.getItem('php_token'));
    // let formdata = new FormData();

    return this.http
      .get('https://rapidpartydev.mobilytedev.com/meetationapi/get-events', {
        headers: headers
      })
      .toPromise()
      .then(res => res);
  }

  getMeetings() {
    let headers = new HttpHeaders();
    headers = headers.append('key', 'a152e84173914146e4bc4f391sd0f686ebc4f31');
    headers = headers.append('accessToken', localStorage.getItem('php_token'));
    // let formdata = new FormData();

    return this.http
      .get('https://rapidpartydev.mobilytedev.com/meetationapi/calendar', {
        headers: headers
      })
      .toPromise()
      .then(res => res);
  }

  getPastEvents() {
    let headers = new HttpHeaders();
    headers = headers.append('key', 'a152e84173914146e4bc4f391sd0f686ebc4f31');
    headers = headers.append('accessToken', localStorage.getItem('php_token'));
    // let formdata = new FormData();

    return this.http
      .get(
        'https://rapidpartydev.mobilytedev.com/meetationapi/get-events?type=4',
        {
          headers: headers
        }
      )
      .toPromise()
      .then(res => res);
  }

  deleteCard(id) {
    let data = {
      headers: { Authorization: localStorage.getItem('node_token') }
    };
    return this.http
      .delete(this.baseUrl + 'service/delete-card/' + id, data)
      .toPromise()
      .then(res => res);
  }

  setAsDefault(id, data) {
    let headers = {
      headers: { Authorization: localStorage.getItem('node_token') }
    };
    return this.http
      .post(this.baseUrl + 'service/update-card/' + id, data, headers)
      .toPromise()
      .then(res => res);
  }

  addCard(data) {
    let headers = {
      headers: { Authorization: localStorage.getItem('node_token') }
    };
    return this.http
      .post(this.baseUrl + 'service/add-card', data, headers)
      .toPromise()
      .then(res => res);
  }

  getCardList() {
    let headers = {
      headers: { Authorization: localStorage.getItem('node_token') }
    };
    return this.http
      .post(this.baseUrl + 'service/list-card', null, headers)
      .toPromise()
      .then(res => res);
  }

  getTimezones() {
    let headers = {
      headers: { Authorization: localStorage.getItem('node_token') }
    };
    return this.http
      .post(this.baseUrl + 'event/timezone', null, headers)
      .toPromise()
      .then(res => res);
  }

  pay(data) {
    let headers = {
      headers: { Authorization: localStorage.getItem('node_token') }
    };
    return this.http
      .post(this.baseUrl + 'service/payment', data, headers)
      .toPromise()
      .then(res => res);
  }

  getNotifications() {
    let headers = {
      headers: { Authorization: localStorage.getItem('node_token') }
    };
    return this.http
      .post(this.baseUrl + 'service/notification', null, headers)
      .toPromise()
      .then(res => res);
  }

  updateNotification() {
    let headers = {
      headers: { Authorization: localStorage.getItem('node_token') }
    };
    return this.http
      .post(this.baseUrl + 'service/update-notification', null, headers)
      .toPromise()
      .then(res => res);
  }

  storeUserData(token: string, phpToken: string) {
    localStorage.setItem('node_token', token);
    localStorage.setItem('php_token', phpToken);
    this.authToken = token;
  }

  loadToken() {
    const token = localStorage.getItem('node_token');
    this.authToken = token;
    return localStorage.getItem('node_token');
  }

  loggedIn() {
    return this.loadToken() !== null;
  }

  logout() {
    this.authToken = null;
    localStorage.clear();
  }
}
