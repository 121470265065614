import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './../authentication/auth.service';
import { SocialAuthService, SocialUser } from 'angularx-social-login';
import {
  FacebookLoginProvider,
  GoogleLoginProvider
} from 'angularx-social-login';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, AfterViewInit {
  email: [''];
  password: [''];
  firstName: [''];
  lastName: [''];
  confirmPassword: [''];
  contactNumber: [''];
  recaptchaReactive: [''];
  termsAndConditions: [''];
  user: SocialUser;
  loggedIn: boolean;
  dataToSignUp: any;
  socialSignUp: any;
  socialType: any;
  graphMeEndpoint = 'https://graph.microsoft.com/v1.0/me';
  profile: any;
  microsoftLogin = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private broadcastService: BroadcastService,
    private messageService: MessageService,
    private spinnerService: Ng4LoadingSpinnerService,
    private AuthService: SocialAuthService,
    private msalService: MsalService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    console.log('user before__________', this.user);
    // console.log("captchaStyle", this.captchaStyle.nativeElement.innerHTML)
    // this.AuthService.authState.subscribe((user) => {
    //   console.log("user signin", user);
    //   if(this.socialSignUp == true){
    //   this.user = user;
    //   if(this.user){
    //     console.log("in if user__________")
    //     this.signUp(this.user);
    //   }
    // }
    //   this.loggedIn = (user != null);
    // });
    this.user = null;
    if (this.microsoftLogin == true) {
      this.getProfile();
    }
  }

  ngAfterViewInit() {
    // console.log("captchaStyle1", this.captchaStyle.elementRef.nativeElement.innerHTML)
  }

  @ViewChild('captchaRef') captchaStyle: ElementRef;

  signUp(formm) {
    this.spinnerService.show();

    if (this.socialSignUp == null) {
      if (this.password != this.confirmPassword) {
        this.spinnerService.hide();

        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Password do not match!'
        });
        return;
      } else {
        this.dataToSignUp = {
          socialType: 'normal',
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
          contactNumber: this.contactNumber
        };
      }
    } else if (this.socialSignUp == true) {
      if (this.socialType == 'google' || this.socialType == 'facebook') {
        {
          this.dataToSignUp = {
            socialType: this.socialType,
            socialId: this.user['id'],
            firstName: this.user['firstName'],
            lastName: this.user['lastName'],
            email: this.user['email'],
            imageUrl: this.user['photoUrl']
          };
        }
      } else {
        this.dataToSignUp = {
          socialType: this.socialType,
          socialId: formm['accountIdentifier'],
          firstName: formm['idToken']['name'],
          // lastName : formm['lastName'],
          email: formm['email'],
          imageUrl: formm['userName']
        };
      }
    }

    console.log('data to signup', this.dataToSignUp);

    this.authService.signUp(this.dataToSignUp).then(res => {
      console.log('response', res);
      if (res['status'] == 1) {
        this.spinnerService.hide();

        this.messageService.add({
          severity: 'success',
          summary: 'Success Message',
          detail: 'User Created!'
        });
        localStorage.setItem('user_image', res['data']['imageUrl']);
        localStorage.setItem('login_type', res['data']['socialType']);
        this.authService.storeUserData(
          res['data']['accessToken'],
          res['response']['data']['access_token']
        );
        setTimeout(() => {
          this.router.navigate(['/dashboard']);
        }, 2000);
      } else {
        this.spinnerService.hide();

        this.messageService.add({
          severity: 'error',
          summary: 'Error Message',
          detail: res['message']
        });
      }
    });
  }

  signInWithGoogle(): void {
    console.log('sign up with google');
    this.socialSignUp = true;
    this.socialType = 'google';
    this.AuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => {
      console.log('x', x);
      if (x) {
        this.user = x;
        this.signUp(this.user);
      }
    });
  }

  signInWithFB(): void {
    console.log('sign up with facebook');
    this.socialSignUp = true;
    this.socialType = 'facebook';
    this.AuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => {
      console.log('x', x);
      if (x) {
        this.user = x;
        this.signUp(this.user);
      }
    });
  }

  signInWithMicrosoft() {
    console.log('microsoft login_______________');
    const isIE =
      window.navigator.userAgent.indexOf('MSIE ') > -1 ||
      window.navigator.userAgent.indexOf('Trident/') > -1;
    this.microsoftLogin == true;
    this.socialSignUp = true;
    this.socialType = 'microsoft';
    if (isIE) {
      this.msalService.loginRedirect({
        extraScopesToConsent: ['user.read', 'openid', 'profile']
      });
      let microsoftUser = this.msalService.getAccount();
      console.log('microsft user', microsoftUser);
      this.signUp(microsoftUser);
      // this.getProfile();
    } else {
      this.msalService.loginPopup({
        extraScopesToConsent: ['user.read', 'openid', 'profile']
      });
      let microsoftUser = this.msalService.getAccount();
      console.log('microsft user', microsoftUser);
      if (microsoftUser) {
        this.signUp(microsoftUser);
      }
      // this.getProfile();
    }
  }

  getProfile() {
    this.http
      .get(this.graphMeEndpoint)
      .toPromise()
      .then(profile => {
        this.profile = profile;
        console.log('user', profile);
      });
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved response token: ${captchaResponse}`);
  }

  captchaResponse(response) {
    console.log('captcha response', response);
  }

  back() {
    this.router.navigate(['/landing']);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  alphabetOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if ((charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      return false;
    }
    return true;
  }
}
