// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  test: "https://api.meetation.com/api/",
  googleClientId: '420160114272-s9fb9v8nhbdp3u4cnq248ke4o95bpi90.apps.googleusercontent.com',
  faceboookAppId: '2625576567543922',
  microsoftAuth: {
    clientId: "90330dda-2f6f-4f6d-9b66-92b6d54fe47e",
    authority: '64a07108-e973-4409-b2f7-6a1400bbaf70',
    redirectUri: 'https://www.meetation.com'
  },
  stripeAccessKey: 'pk_test_51HKAP4A7zbcT7sWY5Zgt1IBEvk6XtBPsaH8nXkiD4V9gNYuDTC24hzPSkMr89BDnWKQRBaW8BMsHng3buAjf8hGX00fCdWvpyM'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
