import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './../authentication/auth.service';
import { SocialAuthService, SocialUser } from 'angularx-social-login';
import {
  FacebookLoginProvider,
  GoogleLoginProvider
} from 'angularx-social-login';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  email: [''];
  dataToSend: {};

  constructor(
    private authService: AuthService,
    private spinnerService: Ng4LoadingSpinnerService,
    private router: Router,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {}

  resetPassword(formm: NgForm) {
    this.spinnerService.show();
    this.dataToSend = {
      email: this.email
    };
    console.log('email to reset', this.dataToSend);
    this.authService.forgotPassword(this.dataToSend).then(res => {
      console.log('res', res);
      if (res['status'] == 1) {
        this.spinnerService.hide();
        this.messageService.add({
          severity: 'success',
          summary: 'Success Message',
          detail: res['message']
        });
        setTimeout(() => {
          this.router.navigate(['/signin']);
        }, 2000);
        formm.reset();
      } else {
        this.spinnerService.hide();
        this.messageService.add({
          severity: 'error',
          summary: ' Error Message',
          detail: res['message']
        });
        localStorage.clear();
      }
    });
  }

  back() {
    this.router.navigate(['signin']);
  }
}
